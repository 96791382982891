import React from 'react'
import styled from 'styled-components'

type Props = {
    label: string
    symbol: string
}

const LogHeader = ({ label, symbol }: Props) => (
    <>
        <span
            className="emoji"
            role="img"
            aria-label={label ? label : ''}
            aria-hidden={label ? 'false' : 'true'}>
            {symbol}
        </span>
        <Text>{label ? ` ${label}` : ''}</Text>
    </>
)

const Text = styled.span`
    font-weight: bold;
`

export default LogHeader
