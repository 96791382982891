import { BREAK } from '../../config'
import {
    ACTIONS,
    API_NAME,
    CAN_CREATE,
    CAN_DELETE,
    CAN_EDIT,
    FILTER_GROUPS,
    IN_SIDE_MENU,
    LAYOUT_FORM,
    LAYOUT_LIST_VIEW,
    OBJECTS_TO_SYNC,
    OBJECTS_TO_SYNC_FIELDS,
    RECORD_LIMIT,
    RECORD_SORT,
    TABS,
} from '../../fields'
import type {
    Action,
    ConfigLayout,
    FilterGroup,
    ObjectToSync,
    RecordSort,
    TabConfig,
} from '../../types/configuration-types'
import {
    checkRecognisedFields,
    isValidArray,
    isValidBoolean,
    isValidNumber,
    isValidObject,
    isValidString,
} from '../helpers/fields'
import { logError, logFieldError, logWarning } from '../helpers/logging'

import { checkActionItem } from './shared/action'
import { checkFilterGroups } from './shared/filterGroups'
import { checkLayout } from './shared/layout'
import { checkRecordSort } from './shared/recordSort'
import { checkTabs } from './shared/tabs'

export const validateObjectsToSync = (items: ObjectToSync[]) => {
    if (isValidArray(items, OBJECTS_TO_SYNC)) {
        items.forEach((item, index) => {
            const id = `${OBJECTS_TO_SYNC} (index ${index})`

            checkRecognisedFields(item, OBJECTS_TO_SYNC_FIELDS, id)
            checkItemDataTypes(item, id)
        })

        checkApiNames(items)
        checkSideMenuOptions(items)
    }
}

export const checkItemDataTypes = (item: ObjectToSync, id: string) => {
    const {
        Actions,
        APIName,
        CanCreate,
        CanDelete,
        CanEdit,
        FilterGroups,
        InSideMenu,
        LayoutForm,
        LayoutListView,
        RecordLimit,
        RecordSort,
        Tabs,
    } = item

    // Actions field
    const actionsId = `${id} -> ${ACTIONS}`
    if (isValidArray(Actions, actionsId)) {
        checkActions(Actions as Action[], actionsId)
    }

    // APIName field
    if (!isValidString(APIName, `${id} -> ${API_NAME}`)) {
        logFieldError(API_NAME, id)
    }

    // CanCreate field
    isValidBoolean(CanCreate, `${id} -> ${CAN_CREATE}`)

    // CanEdit field
    isValidBoolean(CanEdit, `${id} -> ${CAN_EDIT}`)

    // CanDelete field
    isValidBoolean(CanDelete, `${id} -> ${CAN_DELETE}`)

    // FilterGroups field
    const filterGroupsId = `${id} -> ${FILTER_GROUPS}`
    if (isValidArray(FilterGroups, filterGroupsId)) {
        checkFilterGroups(FilterGroups as FilterGroup[], filterGroupsId, true)
    }

    // InSideMenu field
    isValidBoolean(InSideMenu, `${id} -> ${IN_SIDE_MENU}`)

    // LayoutForm field
    const layoutFormId = `${id} -> ${LAYOUT_FORM}`
    if (isValidArray(LayoutForm, layoutFormId)) {
        checkLayout(LayoutForm as ConfigLayout[], layoutFormId)
    }

    // LayoutListView field
    const layoutListId = `${id} -> ${LAYOUT_LIST_VIEW}`
    if (isValidArray(LayoutListView, layoutListId)) {
        checkLayout(LayoutListView as ConfigLayout[], layoutListId)
    }

    // RecordLimit field
    const recordLimitId = `${id} -> ${RECORD_LIMIT}`
    if (isValidNumber(RecordLimit, recordLimitId)) {
        checkRecordLimit(RecordLimit as number, recordLimitId)
    }

    // RecordSort field
    const recordSortId = `${id} -> ${RECORD_SORT}`
    if (isValidObject(RecordSort, recordSortId)) {
        checkRecordSort(RecordSort as RecordSort, recordSortId)
    }

    // Tabs field
    const tabsId = `${id} -> ${TABS}`
    if (isValidArray(Tabs, tabsId)) {
        checkTabs(Tabs as TabConfig[], tabsId)
    }
}

const checkApiNames = (ObjectsToSync: ObjectToSync[]) => {
    const apiNames: Record<string, unknown> = {}

    ObjectsToSync.forEach((obj, index) => {
        const { APIName } = obj

        if (!APIName) {
            return
        }

        const objectId = `${OBJECTS_TO_SYNC} (index ${index})`

        if (apiNames[APIName]) {
            logError(
                `The ${API_NAME} "${APIName}" is defined multiple times.${BREAK}All ${API_NAME} values must be unique.`,
                objectId,
            )
            return
        }
        apiNames[APIName] = true

        const fields = Object.keys(obj)
        if (APIName && fields.length === 1) {
            logError(
                `The ${API_NAME} "${APIName}" is declared but not configured.${BREAK}All objects to sync must have at least one option configured.`,
                objectId,
            )
            return
        }
    })
}

const checkSideMenuOptions = (ObjectsToSync: ObjectToSync[]) => {
    ObjectsToSync.forEach((obj, index) => {
        const { InSideMenu, LayoutListView, LayoutForm, APIName } = obj

        const objectId = `${OBJECTS_TO_SYNC} (index ${index})`

        if (InSideMenu !== true && LayoutListView?.length) {
            logWarning(
                `The ${API_NAME} "${APIName}" has side menu items defined that will not display.${BREAK}The ${IN_SIDE_MENU} field must be true to show these items.`,
                objectId,
            )
            return
        }

        if (InSideMenu === true && !LayoutListView?.length) {
            logError(
                `The ${API_NAME} "${APIName}" has missing side menu items.${BREAK}A ${LAYOUT_LIST_VIEW} field must be included - must be an array with at least one item.`,
                objectId,
            )
            return
        }

        if (LayoutListView?.length && !LayoutForm?.length) {
            logError(
                `The ${API_NAME} "${APIName}" has missing form items.${BREAK}A ${LAYOUT_FORM} field must be included - must be an array with at least one item.`,
                objectId,
            )
            return
        }
    })
}

const checkActions = (actions: Action[], id: string) => {
    actions.forEach((item: Action, index: number) => {
        checkActionItem(item, `${id} (index ${index})`)
    })
}

const checkRecordLimit = (value: number, id: string) => {
    if (value < 0) {
        logError(
            `Invalid "${RECORD_LIMIT}" field.${BREAK}You entered "${value}" - must not be a negative number.`,
            id,
        )
    }
}
