import {
    DIRECTION,
    RECORD_SORT_FIELDS,
    SORT_FIELD_API_NAME,
} from '../../../fields'
import type { RecordSort } from '../../../types/configuration-types'
import { checkRecognisedFields, isValidString } from '../../helpers/fields'
import { logError, logFieldError } from '../../helpers/logging'

// Sort direction values
const ASC = 'ASC'
const DESC = 'DESC'

export const checkRecordSort = (recordSort: RecordSort, id: string) => {
    checkRecognisedFields(recordSort, RECORD_SORT_FIELDS, id)

    const { SortFieldApiName, Direction } = recordSort

    if (!isValidString(SortFieldApiName, SORT_FIELD_API_NAME)) {
        logFieldError(SORT_FIELD_API_NAME, id)
    }

    const hasDirectionField = isValidString(Direction, DIRECTION)
    if (!hasDirectionField) {
        logFieldError(
            DIRECTION,
            id,
            `This is the type of action to trigger - must be either "${ASC}" or "${DESC}".`,
        )
    } else {
        switch (Direction) {
            case ASC:
            case DESC:
                break
            default:
                logError(
                    `Invalid field "${DIRECTION}".\n You entered "${Direction}" - must be either "${ASC}" or "${DESC}".`,
                    `${id} -> ${DIRECTION}`,
                )
        }
    }
}
