import { BREAK } from '../../config'
import {
    ASSET_FIELD_MAPPING_FIELDS,
    AUDIT_TYPES,
    AUDIT_TYPES_FIELDS,
    BUTTON,
    CAN_ADD_PRODUCTS,
    CAN_CREATE_ORDERS,
    CAN_TAKE_PHOTOS,
    FILTER_GROUPS,
    FROM_NAME,
    LAYOUT_FORM,
    OUTLET_ASSET_TO_AUDIT_ASSET,
    QUICK_ADD,
    TABS,
    TITLE,
    TO_NAME,
    TYPE,
} from '../../fields'
import type {
    AuditType,
    ConfigLayout,
    FieldMapping,
    FilterGroup,
    TabConfig,
} from '../../types/configuration-types'
import {
    checkRecognisedFields,
    isValidArray,
    isValidBoolean,
    isValidObject,
    isValidString,
} from '../helpers/fields'
import { logError, logFieldError, logWarning } from '../helpers/logging'

import { checkFilterGroups } from './shared/filterGroups'
import { checkLayout } from './shared/layout'
import { checkTabs } from './shared/tabs'

export const validateAuditTypes = (items: AuditType[]) => {
    if (isValidArray(items, AUDIT_TYPES)) {
        items.forEach((auditType, index) => {
            const id = `${AUDIT_TYPES} (index ${index})`
            checkRecognisedFields(auditType, AUDIT_TYPES_FIELDS, id)
            checkItemDataTypes(auditType, id)
        })
    }
}

const checkItemDataTypes = (item: AuditType, id: string) => {
    const {
        Button,
        CanAddProducts,
        CanCreateOrders,
        CanTakePhotos,
        FilterGroups,
        LayoutForm,
        OutletAssetToAuditAssetFieldMapping,
        QuickAdd,
        Tabs,
        Title,
        Type,
    } = item

    // Button field
    isValidString(Button, `${id} -> ${BUTTON}`)

    // CanAddProducts field
    isValidBoolean(CanAddProducts, `${id} -> ${CAN_ADD_PRODUCTS}`)

    // CanCreateOrders field
    isValidBoolean(CanCreateOrders, `${id} -> ${CAN_CREATE_ORDERS}`)

    // CanTakePhotos field
    isValidBoolean(CanTakePhotos, `${id} -> ${CAN_TAKE_PHOTOS}`)

    // FilterGroups field
    const filterGroupsId = `${id} -> ${FILTER_GROUPS}`
    if (isValidArray(FilterGroups, filterGroupsId)) {
        checkFilterGroups(FilterGroups as FilterGroup[], filterGroupsId)
    }

    // LayoutForm field
    const layoutFormId = `${id} -> ${LAYOUT_FORM}`
    if (isValidArray(LayoutForm, layoutFormId)) {
        checkLayout(LayoutForm as ConfigLayout[], layoutFormId)
    }

    // OutletAssetToAuditAssetFieldMapping field
    const assetMappingId = `${id} -> ${OUTLET_ASSET_TO_AUDIT_ASSET}`
    if (isValidArray(OutletAssetToAuditAssetFieldMapping, assetMappingId)) {
        checkAssetFieldMapping(
            OutletAssetToAuditAssetFieldMapping as FieldMapping[],
            assetMappingId,
        )
    }

    // QuickAdd field
    const quickAddId = `${id} -> ${QUICK_ADD}`
    if (isValidObject(QuickAdd, quickAddId)) {
        logWarning(
            `A ${QUICK_ADD} field has been defined. This feature has not been implemented yet so will be ignored.`,
            id,
        )
        checkTabs([QuickAdd] as TabConfig[], quickAddId)
    }

    // Tabs field
    const tabsId = `${id} -> ${TABS}`
    if (isValidArray(Tabs, tabsId)) {
        checkTabs(Tabs as TabConfig[], tabsId)
    }

    // Title field
    isValidString(Title, `${id} -> ${TITLE}`)

    // Type field
    if (!isValidString(Type, `${id} -> ${TYPE}`)) {
        const message =
            'Must be a string that matches one of the API Names of the audit types set up in the aforza__Type__c picklist of aforza__Audit__c object. For example the defaults are: Stock Check, Merchandise Review, and Promotion Compliance.'

        logFieldError(TYPE, id, message)
    }
}

const checkAssetFieldMapping = (items: FieldMapping[], id: string) => {
    items.forEach((item, index) => {
        const { ToName, FromName } = item

        checkRecognisedFields(item, ASSET_FIELD_MAPPING_FIELDS, id)

        const mappingId = `${id} (index ${index})`

        if (
            !isValidString(ToName, `${mappingId} -> ${TO_NAME}`) ||
            !isValidString(FromName, `${mappingId} -> ${FROM_NAME}`)
        ) {
            logError(
                `Invalid ${OUTLET_ASSET_TO_AUDIT_ASSET} field - missing data.${BREAK}Both ${FROM_NAME} (the outlet asset name) and the ${TO_NAME} (the audit asset) must be defined as strings.`,
                mappingId,
            )
        }
    })
}
