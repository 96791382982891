import { BREAK } from '../../../config'
import { DOCUMENT_SETTINGS_FIELDS, FOOTER, HEADER, LOGO } from '../../../fields'
import type { DocumentSettings } from '../../../types/configuration-types'
import { checkRecognisedFields, isValidString } from '../../helpers/fields'
import { logWarning } from '../../helpers/logging'

export const checkDocumentSettings = (
    settings: DocumentSettings,
    id: string,
) => {
    checkRecognisedFields(settings, DOCUMENT_SETTINGS_FIELDS, id)

    const { Footer, Header, Logo } = settings

    isValidString(Header, `${id} -> ${HEADER}`)

    isValidString(Footer, `${id} -> ${FOOTER}`)

    const logoId = `${id} -> ${LOGO}`
    if (isValidString(Logo, logoId)) {
        if (!Logo?.startsWith('https://')) {
            logWarning(
                `Invalid document settings "${LOGO}" field:${BREAK}"${Logo}"${BREAK}The url of a logo or image to display at the top of the document must be a TLS url - i.e. https:// not http://`,
                logoId,
            )
        }
    }
}
