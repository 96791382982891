import { PRINTER_PAPER_HEIGHT, PRINTER_PAPER_WIDTH } from '../../fields'
import type { SFOptionalNumber } from '../../types/configuration-types'
import { isValidNumber } from '../helpers/fields'
import { logError, logWarning } from '../helpers/logging'

export const validatePrinterPaperSize = (
    width?: SFOptionalNumber,
    height?: SFOptionalNumber,
) => {
    const hasWidth = isValidNumber(width, PRINTER_PAPER_WIDTH)
    const hasHeight = isValidNumber(height, PRINTER_PAPER_HEIGHT)

    if (!hasWidth && !hasHeight) {
        return
    }

    if (hasHeight) {
        checkValue(height as number, PRINTER_PAPER_HEIGHT)

        if (!hasWidth) {
            logWarning(
                `You have defined ${PRINTER_PAPER_HEIGHT} but ${PRINTER_PAPER_WIDTH} is missing. Width will default to A4 paper size.`,
                PRINTER_PAPER_WIDTH,
            )
        }
    }

    if (hasWidth) {
        checkValue(width as number, PRINTER_PAPER_WIDTH)

        if (!hasHeight) {
            logWarning(
                `You have defined ${PRINTER_PAPER_WIDTH} but ${PRINTER_PAPER_HEIGHT} is missing. Height will default to A4 paper size.`,
                PRINTER_PAPER_HEIGHT,
            )
        }
    }
}

const checkValue = (value: number, id: string) => {
    if (value < 0) {
        logError(`The ${id} field cannot be less than 0.`, id)
    }
}
