import { INVOICE_SETTINGS } from '../../fields'
import type { DocumentSettings } from '../../types/configuration-types'
import { checkDocumentSettings } from '../fields/shared/documentSettings'
import { isValidObject } from '../helpers/fields'

export const validateInvoiceDocumentSettings = (settings: DocumentSettings) => {
    if (isValidObject(settings, INVOICE_SETTINGS)) {
        checkDocumentSettings(settings, INVOICE_SETTINGS)
    }
}
