import { RECEIPT_SETTINGS } from '../../fields'
import type { DocumentSettings } from '../../types/configuration-types'
import { checkDocumentSettings } from '../fields/shared/documentSettings'
import { isValidObject } from '../helpers/fields'

export const validateReceiptDocumentSettings = (settings: DocumentSettings) => {
    if (isValidObject(settings, RECEIPT_SETTINGS)) {
        checkDocumentSettings(settings, RECEIPT_SETTINGS)
    }
}
