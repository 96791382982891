import { BREAK } from '../../../config'
import {
    CHILD_OBJECT_API,
    CHILD_OBJECT_LOOKUP,
    FILTER_GROUPS,
    LAYOUT,
    RECORD_LIMIT,
    RECORD_SORT,
    TABS,
    TABS_FIELDS,
    TITLE,
    TYPE,
} from '../../../fields'
import type {
    ConfigLayout,
    FilterGroup,
    RecordSort,
    TabConfig,
} from '../../../types/configuration-types'
import {
    checkRecognisedFields,
    isValidArray,
    isValidNumber,
    isValidObject,
    isValidString,
} from '../../helpers/fields'
import { logError, logFieldError, logWarning } from '../../helpers/logging'

import { checkFilterGroups } from './filterGroups'
import { checkLayout } from './layout'
import { checkRecordSort } from './recordSort'

// Tab type values
const ATTRIBUTES = 'ATTRIBUTES'
const DETAILS = 'DETAILS'
const FORM = 'FORM'
const NOTES = 'NOTES'
const RESOURCES = 'RESOURCES'
const SOBJECT = 'SOBJECT'

export const checkTabs = (tabs: TabConfig[], id: string) => {
    if (!tabs.length) {
        logWarning(
            `Empty ${TABS} field found.${BREAK}This may result in unexpected behaviour such as blank pages appearing in the app.`,
            id,
        )
    }

    tabs.forEach((tab, index) => {
        const {
            Type,
            Title,
            RecordLimit,
            RecordSort,
            Layout,
            FilterGroups,
            ChildObjectLookupApiName,
            ChildObjectApiName,
        } = tab

        const tabId = `${id} (index ${index})`

        checkRecognisedFields(tab, TABS_FIELDS, tabId)

        // Type field
        const tabTypeId = `${tabId} -> ${TYPE}`
        if (isValidString(Type, tabTypeId)) {
            checkTypeValue(Type as string, tabTypeId)
        } else {
            logFieldError(TYPE, tabTypeId)
        }

        // Title field
        isValidString(Title, `${tabId} -> ${TITLE}`)

        // Layout field
        checkLayoutValue(Layout, Type, `${tabId} -> ${LAYOUT}`)

        // RecordLimit field
        const tabRecordLimitId = `${tabId} -> ${RECORD_LIMIT}`
        if (isValidNumber(RecordLimit, tabRecordLimitId)) {
            checkRecordLimit(RecordLimit as number, Type, tabRecordLimitId)
        }

        // RecordSort field
        const tabRecordSortId = `${tabId} -> ${RECORD_SORT}`
        if (isValidObject(RecordSort, tabRecordSortId)) {
            checkRecordSort(RecordSort as RecordSort, tabRecordSortId)
        }

        // FilterGroups field
        const tabFilterGroupsId = `${tabId} -> ${FILTER_GROUPS}`
        if (isValidArray(FilterGroups, tabFilterGroupsId)) {
            checkFilterGroups(FilterGroups as FilterGroup[], tabFilterGroupsId)
        }

        // ChildObjectLookupApiName field
        const tabLookupId = `${tabId} -> ${CHILD_OBJECT_LOOKUP}`
        if (
            !isValidString(ChildObjectLookupApiName, tabLookupId) &&
            Type === SOBJECT
        ) {
            logFieldError(
                CHILD_OBJECT_LOOKUP,
                tabLookupId,
                `This field is required because the "${TYPE}" field for this tab is "${Type}". Must be a string.${BREAK}The field on the child object where the parent object’s Id will be found. For example if the parent is an Account and the child is an Order, this field will be AccountId, because Order.AccountId is the lookup field for linking an Order to an Account.`,
            )
        }

        // ChildObjectApiName field
        const tabObjectApiId = `${tabId} -> ${CHILD_OBJECT_API}`
        if (
            !isValidString(ChildObjectApiName, tabObjectApiId) &&
            Type === SOBJECT
        ) {
            logFieldError(
                CHILD_OBJECT_API,
                tabObjectApiId,
                `This field is required because the "${TYPE}" field for this tab is "${Type}".${BREAK}This should be the name of the child object to show in this tab.`,
            )
        }
    })
}

const checkTypeValue = (value: string, id: string) => {
    switch (value) {
        case ATTRIBUTES:
        case DETAILS:
        case FORM:
        case NOTES:
        case RESOURCES:
        case SOBJECT:
            return
        default:
            logError(
                `Invalid "${TYPE}" field.${BREAK}You entered "${value}" - must be one of "${ATTRIBUTES}", "${DETAILS}", "${FORM}", "${NOTES}", "${RESOURCES}", "${SOBJECT}"`,
                id,
            )
    }
}

const checkRecordLimit = (value: number, tabType: unknown, id: string) => {
    if (tabType !== SOBJECT) {
        logWarning(
            `A ${RECORD_LIMIT} field has been defined but the ${TYPE} field for this tab is "${tabType}".${BREAK}This field will be ignored because ${RECORD_LIMIT} is only used when the ${TYPE} field is "${SOBJECT}".`,
            id,
        )
        return
    }

    if (value < 0) {
        logError(
            `Invalid "${RECORD_LIMIT}" field.${BREAK}You entered "${value}" - must not be a negative number.`,
            id,
        )
    }
}

const checkLayoutValue = (
    layout: unknown,
    type: string | null | undefined,
    id: string,
) => {
    const layoutRequired = type === SOBJECT || type === FORM

    if (isValidArray(layout, LAYOUT)) {
        switch (type) {
            case SOBJECT:
                checkLayout(layout as ConfigLayout[], id, true)
                break
            case DETAILS:
            case FORM:
                checkLayout(layout as ConfigLayout[], id)
                break
            default:
                logWarning(
                    `A ${LAYOUT} field has been declared but the ${TYPE} field is "${type}". This will be ignored.${BREAK}The ${LAYOUT} field can only be used when the ${TYPE} field is one of ${SOBJECT}, ${DETAILS}, ${FORM}.`,
                    id,
                )
        }
    } else if (layoutRequired) {
        logFieldError(
            LAYOUT,
            id,
            `This field is required because the "${TYPE}" field is "${type}". Must be an array.`,
        )
    }
}
