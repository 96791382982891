import { BREAK, LOG_TYPES, UPDATE_ERROR_LOGS } from '../../config'
import type { AforzaLog } from '../../types/log-types'

const { CONGRATS, WARNING, ERROR } = LOG_TYPES

let logs = [] as Array<AforzaLog>

export const logValidationComplete = (name = '') => {
    if (!logs.length) {
        logs.push({ type: CONGRATS, message: 'No issues found' })
    }

    if (name) {
        logs.unshift({ type: name, message: '' })
    }

    const event = new CustomEvent(UPDATE_ERROR_LOGS, {
        detail: [...logs],
    })

    window.dispatchEvent(event)
    logs = []
}

export const logFieldError = (name: string, location: string, message = '') => {
    const appendedMessage = message ? `${BREAK}${message}` : ''

    logError(
        `Invalid or missing required field:${BREAK}"${name}"${appendedMessage}`,
        location,
    )
}

export const logError = (message: string, location = '') => {
    const locationText = location ? `Location: ${location}` : ''
    logs.push({ type: ERROR, message: `${locationText}${BREAK}${message}` })
}

export const logWarning = (message: string, location = '') => {
    const locationText = location ? `Location:${location}` : ''
    logs.push({ type: WARNING, message: `${locationText}${BREAK}${message}` })
}
