import type { ChangeEvent } from 'react'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import '../App.css'
import { BREAK, PALETTE } from '../config'
import type { MobileConfig } from '../types/configuration-types'
import { validateAllFields } from '../validation'
import { logError, logValidationComplete } from '../validation/helpers/logging'

import ValidateButton from './ValidateButton'

type Props = {
    hasLogs: boolean
    resetLogs: () => void
}

const FileUploader = ({ resetLogs, hasLogs }: Props) => {
    const [selectedFile, setSelectedFile] = useState<string>('')
    const [fileName, setFileName] = useState('')

    const inputEl = useRef<HTMLInputElement>(null)

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        resetLogs()

        const fileReader = new FileReader()

        const input = event.target

        if (!input.files?.length) {
            resetForm()
            return
        }

        setFileName(input.files[0].name)
        fileReader.readAsText(input.files[0], 'UTF-8')

        fileReader.onload = () => {
            setSelectedFile(fileReader.result as string)
        }
    }

    const onFileUpload = () => {
        if (!fileName) {
            alert('No file selected!')
            return
        }

        try {
            const config: MobileConfig = JSON.parse(selectedFile)
            validateAllFields(config, fileName)

            resetForm()
        } catch (e) {
            logError(`The uploaded file is not valid JSON:${BREAK}${e}`)
            resetForm()
            logValidationComplete(fileName)
            return
        }
    }

    const resetForm = () => {
        if (inputEl.current) {
            inputEl.current.value = ''
        }
        setSelectedFile('')
        setFileName('')
    }

    return (
        <InputWrapper>
            <FileInput className="custom-file-upload">
                <input
                    type="file"
                    ref={inputEl}
                    onChange={onFileChange}
                    accept="application/JSON"
                />
                <Content>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24"
                        height="30px"
                        viewBox="0 0 24 24"
                        width="30px"
                        fill={PALETTE.tertiaryBlue}>
                        <g>
                            <rect fill="none" height="24" width="24" />
                        </g>
                        <g>
                            <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z" />
                        </g>
                    </svg>

                    <FileName>{fileName ? fileName : 'Select File'}</FileName>
                </Content>
            </FileInput>
            <ValidateButton onValidate={onFileUpload} isHidden={hasLogs} />
        </InputWrapper>
    )
}

const InputWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 20px;
`

const FileInput = styled.label`
    align-items: center;
    background-color: ${PALETTE.white};
    border: 0;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    color: ${PALETTE.black};
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    padding: 6px 12px;
    width: 200px;
    margin: 10px 0;

    input[type='file'] {
        display: none;
    }
`

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const FileName = styled.span`
    margin: 0 10px;
`

export default FileUploader
