import { ROOT_FIELDS } from '../fields'
import type {
    AuditType,
    DocumentSettings,
    MobileConfig,
    ObjectToSync,
    ScreenLayoutItem,
} from '../types/configuration-types'

import { validateAccountRadius } from './fields/accountRadius'
import { validateAuditTypes } from './fields/auditTypes'
import { validateAutoDownload } from './fields/autoDownload'
import { validateHomePage } from './fields/homePage'
import { validateInvoiceDocumentSettings } from './fields/invoiceDocumentSettings'
import { validateObjectsToSync } from './fields/objectsToSync'
import { validateOrderComplete } from './fields/orderComplete'
import { validatePrinterPaperSize } from './fields/printerPaperSize'
import { validateReceiptDocumentSettings } from './fields/receiptDocumentSettings'
import { validateSideMenu } from './fields/sideMenu'
import { validateSupportEnabled } from './fields/supportEnabled'
import { checkRecognisedFields } from './helpers/fields'
import { logValidationComplete } from './helpers/logging'

export const validateAllFields = (config: MobileConfig, name = '') => {
    checkRecognisedFields(config, ROOT_FIELDS, 'root')

    console.log('TEST')

    const {
        AccountsMaxRadiusKm,
        AccountsMinRadiusKm,
        AuditTypes,
        AutoDownloadVisitResources,
        HomePage,
        InvoiceDocumentSettings,
        IsSupportEnabled,
        ObjectsToSync,
        OrderComplete,
        PrinterPaperHeightMm,
        PrinterPaperWidthMm,
        ReceiptDocumentSettings,
        SideMenu,
    } = config

    validateAccountRadius(AccountsMaxRadiusKm, AccountsMinRadiusKm)

    validateAuditTypes(AuditTypes as AuditType[])

    validateAutoDownload(AutoDownloadVisitResources)

    validateHomePage(HomePage as ScreenLayoutItem[])

    validateInvoiceDocumentSettings(InvoiceDocumentSettings as DocumentSettings)

    validateSupportEnabled(IsSupportEnabled)

    validateObjectsToSync(ObjectsToSync as ObjectToSync[])

    validateOrderComplete(OrderComplete as ScreenLayoutItem[])

    validatePrinterPaperSize(PrinterPaperWidthMm, PrinterPaperHeightMm)

    validateReceiptDocumentSettings(ReceiptDocumentSettings as DocumentSettings)

    validateSideMenu(SideMenu as ScreenLayoutItem[])

    logValidationComplete(name)
}
