import React, { useEffect, useRef, useState } from 'react'
import Switch from 'react-switch'
import styled from 'styled-components'

import { LOG_TYPES, PALETTE } from '../config'
import type { AforzaLog } from '../types/log-types'

import LogItem from './LogItem'

const { ERROR, WARNING } = LOG_TYPES

type Props = {
    data: AforzaLog[]
}

const LogDisplay = ({ data }: Props) => {
    const logEl = useRef<HTMLDivElement>(null)

    const [showWarnings, setShowWarnings] = useState(false)
    const [showErrors, setShowErrors] = useState(true)
    const [warningCount, setWarningCount] = useState(0)
    const [errorCount, setErrorCount] = useState(0)

    useEffect(() => {
        if (data.length && logEl.current) {
            logEl.current.scrollIntoView({ behavior: 'smooth' })
        }

        let errors = 0
        let warnings = 0

        data.forEach((log) => {
            if (log.type === WARNING) {
                warnings++
            } else if (log.type === ERROR) {
                errors++
            }
        })
        setErrorCount(errors)
        setWarningCount(warnings)

        setShowErrors(!!errors)
        setShowWarnings(false)
    }, [data])

    return (
        <Wrapper ref={logEl}>
            <Header>
                <h2>Validation Complete</h2>
                <Summary>
                    <span>{errorCount} error(s) found.</span>
                    <label>
                        <Switch
                            onChange={setShowErrors}
                            checked={showErrors}
                            className="react-switch"
                            onColor={PALETTE.primary}
                            offColor={PALETTE.tertiaryGreyDarkest}
                            disabled={!errorCount}
                        />
                    </label>
                </Summary>

                <Summary>
                    <span>{warningCount} warning(s) found.</span>
                    <label>
                        <Switch
                            onChange={setShowWarnings}
                            checked={showWarnings}
                            className="react-switch"
                            onColor={PALETTE.primary}
                            offColor={PALETTE.tertiaryGreyDarkest}
                            disabled={!warningCount}
                        />
                    </label>
                </Summary>
            </Header>

            {data.map((log) => (
                <LogItem
                    log={log}
                    showErrors={showErrors}
                    showWarnings={showWarnings}
                    key={log.message}
                />
            ))}
        </Wrapper>
    )
}

const Header = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${PALETTE.white};
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    margin: 20px 0;
    padding: 10px 0;
`

const Wrapper = styled.div`
    align-items: center;
    border-top: 1px solid ${PALETTE.tertiaryGreyDarkest};
    display: flex;
    flex-direction: column;
`

const Summary = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 3px 0;
    width: 50%;
`
export default LogDisplay
