import type { ChangeEvent } from 'react'
import React, { useState } from 'react'
import styled from 'styled-components'

import '../App.css'
import { BREAK, PALETTE } from '../config'
import type { MobileConfig } from '../types/configuration-types'
import { validateAllFields } from '../validation'
import { logError, logValidationComplete } from '../validation/helpers/logging'

import ValidateButton from './ValidateButton'

type Props = {
    hasLogs: boolean
    resetLogs: () => void
}

const TextInput = ({ resetLogs, hasLogs }: Props) => {
    const [configText, setConfigText] = useState('')

    const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        resetLogs()
        setConfigText(e.target.value)
    }

    const onTextUpload = () => {
        if (!configText) {
            alert('No text entered!')
            return
        }

        try {
            const config: MobileConfig = JSON.parse(configText)
            validateAllFields(config)
        } catch (e) {
            logError(`The text entered is not valid JSON:${BREAK}${e}`)
            logValidationComplete()
            return
        }
    }

    return (
        <InputWrapper>
            <Input
                onChange={onTextChange}
                rows={20}
                value={configText}
                placeholder={'Type or paste your config text here...'}
            />
            <ValidateButton onValidate={onTextUpload} isHidden={hasLogs} />
        </InputWrapper>
    )
}

const InputWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`

const Input = styled.textarea`
    border: 0;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    margin: 20px 0px;
    outline-color: ${PALETTE.secondaryCobalt};
    padding: 20px;
    width: 90%;
`

export default TextInput
