import { BREAK } from '../../../config'
import {
    FILTER_FIELDS,
    FILTER_GROUP_FIELDS,
    FILTERS,
    KEY,
    OPERATOR,
    VALUE,
    VALUES,
} from '../../../fields'
import type {
    ConfigFilter,
    FilterGroup,
} from '../../../types/configuration-types'
import {
    checkRecognisedFields,
    isValidArray,
    isValidObject,
    isValidString,
} from '../../helpers/fields'
import { logError, logFieldError } from '../../helpers/logging'
import { isValidSFDateLiteral } from '../../helpers/salesforce-dates'

// Operators values
const IN = 'in'
const GT = 'gt'
const LT = 'lt'
const GE = 'ge'
const LE = 'le'
const EQ = 'eq'
const NE = 'ne'

// Special string values
const USER_ID = '{{userId}}'
const ACCOUNT_IDS = '{{accountIds}}'
const CONTACT_IDS = '{{contactIds}}'

export const checkFilterGroups = (
    filterGroups: FilterGroup[],
    id: string,
    isObjectToSync = false,
) => {
    filterGroups.forEach((item, groupIndex) => {
        const { Filters } = item

        const groupId = `${id} (index ${groupIndex})`

        checkRecognisedFields(item, FILTER_GROUP_FIELDS, groupId)

        const filtersId = `${groupId} -> ${FILTERS}`

        if (isValidArray(Filters, filtersId)) {
            Filters?.forEach((filterItem: ConfigFilter, itemIndex: number) => {
                const itemId = `${filtersId} (index ${itemIndex})`

                if (isValidObject(filterItem, itemId)) {
                    checkFilter(filterItem, itemId, isObjectToSync)
                }
            })
        }
    })
}

const checkFilter = (
    filter: ConfigFilter,
    id: string,
    isObjectToSync: boolean,
) => {
    checkRecognisedFields(filter, FILTER_FIELDS, id)

    // Ensure only "Value" OR "Values" is defined - not both!
    if (!hasSingleValue(filter, id)) {
        return
    }

    const { Key, Operator, Value } = filter

    const filterKeyId = `${id} -> ${KEY}`
    const filterOperatorId = `${id} -> ${OPERATOR}`
    const filterValueId = `${id} -> ${VALUE}`

    // Key field
    if (!isValidString(Key, filterKeyId)) {
        logFieldError(KEY, filterKeyId)
    }

    // Operator field
    if (isValidString(Operator, filterOperatorId)) {
        switch (Operator) {
            case IN:
                checkInOperator(filter, id, isObjectToSync)
                break
            case GT:
            case LT:
            case LE:
            case GE: {
                const errorMessage = `Invalid filter value found: "${Value}".${BREAK}The "${VALUE}" must be a numeric string or a Salesforce Date Literal when the operator value is "${Operator}".`

                if (isValidString(Value, filterValueId)) {
                    const valueAsNumber = parseFloat(Value as string)
                    if (
                        isNaN(valueAsNumber) &&
                        !isValidSFDateLiteral(Value as string, filterValueId)
                    ) {
                        logError(errorMessage, filterValueId)
                    }
                } else {
                    logError(errorMessage, filterValueId)
                }
                break
            }
            case EQ:
            case NE:
                // We allow null values for equality checks
                if (Value !== null) {
                    checkStringValue({
                        value: Value,
                        id: filterValueId,
                        fieldName: VALUE,
                        isObjectToSync,
                        operator: Operator as string,
                    })
                }
                break
            default:
                logError(
                    `Invalid filter "${OPERATOR}" field value.${BREAK}Must be one of:${BREAK}"${EQ}" (equal); "${NE}" (not equal); "${IN}" (in); "${LT}" (less than); "${GT}" (greater than); "${LE}" (less than or equal); "${GE}" ("greater than or equal).`,
                    filterOperatorId,
                )
        }
    } else {
        logFieldError(OPERATOR, id)
        return
    }
}

type CheckStringValueParams = {
    value: string | null
    id: string
    fieldName: string
    isObjectToSync: boolean
    operator: string
}

const checkStringValue = ({
    value,
    id,
    fieldName,
    operator,
    isObjectToSync,
}: CheckStringValueParams) => {
    if (!isValidString(value, id)) {
        logError(
            `Invalid or missing item found in "${fieldName}" field.${BREAK}Must be a string value`,
            id,
        )
        return
    }

    // Operator specific special values are always allowed
    if (operator === IN) {
        if (value === ACCOUNT_IDS || value === CONTACT_IDS) {
            return
        }
    } else if (value === USER_ID || value === 'true' || value === 'false') {
        return
    }

    // ObjectToSync string values need to be wrapped in extra ""
    if (isObjectToSync && (!value?.startsWith("'") || !value?.endsWith("'"))) {
        if (isNaN(parseFloat(value as string))) {
            logError(
                `Invalid ${VALUE} field: "${value}"" found in "${fieldName}" field.${BREAK}String values for ObjectsToSync.FilterGroups that are not numbers or booleans MUST use single quotes wrapped in double quotes. For example: "'Ice Cream'"`,
                id,
            )
        }
    }
}

const hasSingleValue = (filter: ConfigFilter, id: string) => {
    const { Value, Values } = filter

    if (
        Value !== undefined &&
        Value !== null &&
        Values !== undefined &&
        Values !== null
    ) {
        logError(
            `Multiple value fields found.${BREAK}Either "${VALUE}" OR "${VALUES}" field must be defined - not both.`,
            id,
        )
        return false
    }
    return true
}

const checkInOperator = (
    filter: ConfigFilter,
    id: string,
    isObjectToSync: boolean,
) => {
    const { Value, Values } = filter

    const filterValueId = `${id} -> ${VALUE}`
    const filterValuesId = `${id} -> ${VALUES}`

    if (Value !== undefined && Value !== null) {
        if (
            isValidString(Value, filterValueId) &&
            Value !== ACCOUNT_IDS &&
            Value !== CONTACT_IDS
        ) {
            logError(
                `Invalid "${VALUE}" field "${Value}".${BREAK}When using the '${IN}' operator the "${VALUE}" field must be either "${ACCOUNT_IDS}" or "${CONTACT_IDS}"`,
                filterValueId,
            )
        }
        return
    }

    if (!isValidArray(Values, filterValuesId)) {
        const message = `Filters using the '${IN}' operator must define either the "${VALUE}" field (must be a string value) or the "${VALUES}" field (must be an array of string, number or boolean values).`

        logFieldError(VALUES, filterValuesId, message)
        return
    }
    Values?.forEach((val: string, index: number) => {
        checkStringValue({
            value: val,
            id: `${filterValuesId} (index ${index})`,
            fieldName: VALUES,
            isObjectToSync,
            operator: IN,
        })
    })
}
