import { BREAK } from '../../config'
import { AUTO_DOWNLOAD_RESOURCES } from '../../fields'
import { isValidBoolean } from '../helpers/fields'
import { logWarning } from '../helpers/logging'

export const validateAutoDownload = (value?: boolean | null) => {
    if (isValidBoolean(value, AUTO_DOWNLOAD_RESOURCES)) {
        if (value) {
            logWarning(
                `Warning! You have set ${AUTO_DOWNLOAD_RESOURCES} to true.${BREAK} Enabling auto downloads can significantly increase initial sync time and the cached files may consume a large amount of disk space.`,
                AUTO_DOWNLOAD_RESOURCES,
            )
        }
    }
}
