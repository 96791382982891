import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import FileUploader from './components/FileUploader'
import LogDisplay from './components/LogDisplay'
import TextInput from './components/TextInput'
import { PALETTE, UPDATE_ERROR_LOGS } from './config'
import logo from './logo_colour_320px.svg'
import type { AforzaLog } from './types/log-types'

import './App.css'

const TEXT = 'Enter as text'
const FILE = 'Upload file'
interface ToggleProps {
    readonly active: boolean
}

const App = () => {
    const [showFile, setShowFile] = useState(false)
    const [logs, setLogs] = useState<AforzaLog[]>([])

    const handleInputChange = () => {
        setShowFile(!showFile)
        clearLogs()
    }

    const handleUpdateLogs = (e: CustomEvent) => {
        setLogs(e.detail)
    }

    const clearLogs = () => setLogs([])

    useEffect(() => {
        window.addEventListener(
            UPDATE_ERROR_LOGS,
            handleUpdateLogs as EventListener,
        )

        return () => {
            window.removeEventListener(
                UPDATE_ERROR_LOGS,
                handleUpdateLogs as EventListener,
            )
        }
    }, [])

    return (
        <Wrapper>
            <Header>
                <Logo src={logo} alt="logo" />
            </Header>

            <p>
                Enter your mobile config below and press{' '}
                <strong>validate</strong> to check for errors.
            </p>

            <WarningText>
                {`WARNING! It is strongly recommended that you also check your format using `}
                <Link
                    href="https://jsonlint.com/"
                    target="_blank"
                    rel="noopener noreferrer">
                    {`JSON Lint`}
                </Link>
                {`.`}
            </WarningText>

            <ToggleButton onClick={handleInputChange}>
                <ToggleBackground>
                    <ToggleLeft>{TEXT}</ToggleLeft>
                    <ToggleRight>{FILE}</ToggleRight>
                </ToggleBackground>
                <ToggleSelection active={!showFile}>
                    <span>{`${!showFile ? TEXT : FILE}`}</span>
                </ToggleSelection>
            </ToggleButton>

            {showFile ? (
                <FileUploader resetLogs={clearLogs} hasLogs={!!logs.length} />
            ) : (
                <TextInput resetLogs={clearLogs} hasLogs={!!logs.length} />
            )}

            {logs.length ? <LogDisplay data={logs} /> : null}
        </Wrapper>
    )
}

const Header = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
`

const Link = styled.a`
    color: ${PALETTE.secondaryCobalt};
`

const Logo = styled.img`
    margin: 30px 0;
    pointer-events: none;
    width: 230px;
`

const ToggleButton = styled.button`
    background-color: ${PALETTE.tertiaryGrey};
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    margin: 15px 0;
    position: relative;
    width: 300px;
`

const ToggleBackground = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const ToggleLeft = styled.span`
    padding-left: 33px;
`

const ToggleRight = styled.span`
    padding-right: 33px;
`

const ToggleSelection = styled.div<ToggleProps>`
    align-items: center;
    background-color: ${PALETTE.white};
    border-radius: 100px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    display: flex;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    left: ${(props) => (props.active ? '0' : '150px')};
    position: absolute;
    top: 0;
    width: 150px;
`

const WarningText = styled.p`
    font-size: 14px;
    padding: 20px;
`

const Wrapper = styled.div`
    background-color: ${PALETTE.tertiaryGreyLight};
    color: ${PALETTE.black};
    font-family: sans-serif;
    padding-bottom: 20px;
    min-height: 100vh;
    text-align: center;
`

export default App
