import React from 'react'
import styled from 'styled-components'

import { PALETTE } from '../config'

type Props = {
    isHidden: boolean
    onValidate: () => void
}

const ValidateButton = (props: Props) => {
    const { onValidate, isHidden } = props

    return !isHidden ? <Button onClick={onValidate}>Validate</Button> : null
}

const Button = styled.button`
    background-color: ${PALETTE.primaryDark};
    border: 0;
    border-radius: 8px;
    color: ${PALETTE.white};
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    margin: 5px;
    padding: 5px;
    width: 200px;
`

export default ValidateButton
