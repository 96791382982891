import { BREAK } from '../../../config'
import { ACTION as ACTION_FIELD, NAME, TYPE } from '../../../fields'
import type {
    Action,
    ScreenLayoutItem,
} from '../../../types/configuration-types'
import { isValidObject, isValidString } from '../../helpers/fields'
import { logError, logFieldError, logWarning } from '../../helpers/logging'

import { checkActionItem } from './action'

// Layout type values
const ACTION = 'ACTION'
const COMPONENT = 'COMPONENT'
const SOBJECT = 'SOBJECT'

export const checkScreenLayoutItem = (item: ScreenLayoutItem, id: string) => {
    const { Action, Name, Type } = item

    // Name field
    const nameId = `${id} -> ${NAME}`
    if (!isValidString(Name, nameId)) {
        logFieldError(NAME, nameId)
    }

    // Type field
    const typeId = `${id} -> ${TYPE}`
    if (isValidString(Type, typeId)) {
        checkTypeValue(Type as string, typeId)
    } else {
        logFieldError(TYPE, typeId)
    }

    // Action field
    const actionId = `${id} -> ${ACTION_FIELD}`
    const hasActionField = isValidObject(Action, actionId)

    if (!hasActionField && Type === ACTION) {
        logError(
            `Invalid layout configuration.${BREAK}The "${ACTION_FIELD}" field must be defined if the "${TYPE}" field is "${ACTION}".`,
            id,
        )
    } else if (hasActionField) {
        checkActionItem(Action as Action, actionId)
    }
}

const checkTypeValue = (value: string, id: string) => {
    switch (value) {
        case ACTION:
            return
        case COMPONENT:
        case SOBJECT:
            logWarning(
                `You entered "${value}" as a screen layout type. This is currently unsupported and will not show in the app`,
                id,
            )
            return
        default:
            logError(
                `Invalid "${TYPE}" field.${BREAK}You entered "${value}" - must be one of "${ACTION}", "${COMPONENT}", "${SOBJECT}"`,
                id,
            )
    }
}

export const checkActionNamesAreUnique = (
    items: ScreenLayoutItem[],
    id: string,
) => {
    const actionNames: Record<string, unknown> = {}

    items.forEach((item, index) => {
        const { Name, Type } = item

        if (!Name) {
            return
        }

        const nameId = `${id} (index ${index}) -> ${NAME}`

        if (Type === ACTION) {
            if (actionNames[Name]) {
                logError(
                    `Duplicate "ACTION" Name: "${Name}"${BREAK}The "${NAME}" field must be a unique identifier for the action when the "${TYPE}" field is "${ACTION}".`,
                    nameId,
                )
                return
            }
            actionNames[Name] = true
        }
    })
}
