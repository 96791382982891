import { ACCOUNT_RADIUS_MAX, ACCOUNT_RADIUS_MIN } from '../../fields'
import type { SFOptionalNumber } from '../../types/configuration-types'
import { isValidNumber } from '../helpers/fields'
import { logError } from '../helpers/logging'

export const validateAccountRadius = (
    maxValue?: SFOptionalNumber,
    minValue?: SFOptionalNumber,
) => {
    const hasMax = isValidNumber(maxValue, ACCOUNT_RADIUS_MAX)
    const hasMin = isValidNumber(minValue, ACCOUNT_RADIUS_MIN)

    if (!hasMax && !hasMin) {
        return
    }

    if (hasMax && !hasMin) {
        logError(
            `You have defined ${ACCOUNT_RADIUS_MAX} but ${ACCOUNT_RADIUS_MIN} is missing.`,
            ACCOUNT_RADIUS_MAX,
        )
        return
    } else if (!hasMax && hasMin) {
        logError(
            `You have defined ${ACCOUNT_RADIUS_MIN} but ${ACCOUNT_RADIUS_MAX} is missing.`,
            ACCOUNT_RADIUS_MIN,
        )
        return
    }

    checkValues(maxValue as number, minValue as number)
}

const checkValues = (max: number, min: number) => {
    if (min < 0) {
        logError(
            `The ${ACCOUNT_RADIUS_MIN} field cannot be less than 0.`,
            ACCOUNT_RADIUS_MIN,
        )
    }

    if (max <= min) {
        logError(
            `The ${ACCOUNT_RADIUS_MAX} field must be a higher number than ${ACCOUNT_RADIUS_MIN}.`,
            ACCOUNT_RADIUS_MAX,
        )
    }
}
