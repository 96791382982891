import { logError } from './logging'

const simpleSFDateLiterals = [
    'YESTERDAY',
    'TODAY',
    'TOMORROW',
    'LAST_WEEK',
    'THIS_WEEK',
    'NEXT_WEEK',
    'LAST_MONTH',
    'THIS_MONTH',
    'NEXT_MONTH',
    'LAST_90_DAYS',
    'NEXT_90_DAYS',
    'THIS_QUARTER',
    'LAST_QUARTER',
    'NEXT_QUARTER',
    'THIS_YEAR',
    'LAST_YEAR',
    'NEXT_YEAR',
    'THIS_FISCAL_QUARTER',
    'LAST_FISCAL_QUARTER',
    'NEXT_FISCAL_QUARTER',
    'THIS_FISCAL_YEAR',
    'LAST_FISCAL_YEAR',
    'NEXT_FISCAL_YEAR',
]

const variableSFDateLiterals = [
    'LAST_N_DAYS:',
    'NEXT_N_DAYS:',
    'NEXT_N_WEEKS:',
    'LAST_N_WEEKS:',
    'NEXT_N_MONTHS:',
    'LAST_N_MONTHS:',
    'NEXT_N_QUARTERS:',
    'LAST_N_QUARTERS:',
    'NEXT_N_YEARS:',
    'LAST_N_YEARS:',
    'NEXT_N_FISCAL_​QUARTERS:',
    'LAST_N_FISCAL_​QUARTERS:',
    'NEXT_N_FISCAL_​YEARS:',
    'LAST_N_FISCAL_​YEARS:',
]

export const isValidSFDateLiteral = (value: string, id: string) => {
    if (simpleSFDateLiterals.includes(value)) {
        return true
    }

    for (let i = 0; i < variableSFDateLiterals.length; i++) {
        const current = variableSFDateLiterals[i]

        if (value.startsWith(current)) {
            const splitValues = value.split(':')
            const nValue = splitValues[1]

            const converted = parseFloat(nValue)

            if (isNaN(converted)) {
                logError(
                    `Invalid Date Literal value: "${value}"\n\nThe n value must be a number. For example, "LAST_N_DAYS:10"`,
                    id,
                )
                return false
            }
            return true
        }
    }

    logError(`Invalid Date Literal value: "${value}"`, id)
    return false
}
