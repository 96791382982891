import React from 'react'
import styled from 'styled-components'

import { BREAK, LOG_TYPES, PALETTE } from '../config'
import type { AforzaLog } from '../types/log-types'

import LogHeader from './LogItemHeader'

const { ERROR, WARNING, CONGRATS } = LOG_TYPES

type Props = {
    log: AforzaLog
    showWarnings: boolean
    showErrors: boolean
}

const LogItem = ({ log, showWarnings, showErrors }: Props) => {
    if (
        (log.type === WARNING && !showWarnings) ||
        (log.type === ERROR && !showErrors)
    ) {
        return null
    }

    let titleSymbol = ''
    let color = ''

    switch (log.type) {
        case WARNING:
            titleSymbol = '⚠️'
            color = PALETTE.labelYellow
            break
        case ERROR:
            titleSymbol = '❗'
            color = PALETTE.labelRed
            break
        case CONGRATS:
            titleSymbol = '✅'
            color = PALETTE.labelGreen
            break
        default:
            color = PALETTE.white
    }

    const text = log.message.split(BREAK)

    return (
        <Item
            key={log.message}
            style={{
                backgroundColor: color,
            }}>
            <LogHeader label={log.type} symbol={titleSymbol} />
            {text.map((str) => {
                if (str.startsWith('Location:')) {
                    const text = str
                        .replace('Location:', '')
                        .replaceAll('->', '>>')

                    return (
                        <LogLine key={str}>
                            <Locator>{text}</Locator>
                        </LogLine>
                    )
                }

                return <LogLine key={str}>{str}</LogLine>
            })}
        </Item>
    )
}

const Item = styled.div`
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    margin: 5px;
    padding: 20px 0;
    width: 90%;
`
const LogLine = styled.p`
    margin: 10px 0;
`

const Locator = styled.span`
    font-family: monospace;
    font-size: 16px;
`

export default LogItem
