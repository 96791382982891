import { SCREEN_LAYOUT_FIELDS, SIDE_MENU } from '../../fields'
import type { ScreenLayoutItem } from '../../types/configuration-types'
import { checkRecognisedFields, isValidArray } from '../helpers/fields'

import {
    checkActionNamesAreUnique,
    checkScreenLayoutItem,
} from './shared/screenLayout'

export const validateSideMenu = (items: ScreenLayoutItem[]) => {
    if (isValidArray(items, SIDE_MENU)) {
        items.forEach((item, index) => {
            const id = `${SIDE_MENU} (index ${index})`

            checkRecognisedFields(item, SCREEN_LAYOUT_FIELDS, id)
            checkScreenLayoutItem(item, id)
        })

        checkActionNamesAreUnique(items, SIDE_MENU)
    }
}
