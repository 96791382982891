import { HOME_PAGE, SCREEN_LAYOUT_FIELDS } from '../../fields'
import type { ScreenLayoutItem } from '../../types/configuration-types'
import { checkRecognisedFields, isValidArray } from '../helpers/fields'

import {
    checkActionNamesAreUnique,
    checkScreenLayoutItem,
} from './shared/screenLayout'

export const validateHomePage = (items: ScreenLayoutItem[]) => {
    if (isValidArray(items, HOME_PAGE)) {
        items.forEach((item, index) => {
            const id = `${HOME_PAGE} (index ${index})`

            checkRecognisedFields(item, SCREEN_LAYOUT_FIELDS, id)
            checkScreenLayoutItem(item, id)
        })

        checkActionNamesAreUnique(items, HOME_PAGE)
    }
}
