import { BREAK } from '../../config'

import { logError } from './logging'

export const checkRecognisedFields = (
    config: Record<string, unknown>,
    recognisedFields: string[],
    name = '',
) => {
    const fieldsInConfig = Object.keys(config)
    const invalidFields: string[] = []

    fieldsInConfig.forEach((field) => {
        if (!recognisedFields.includes(field)) {
            invalidFields.push(field)
        }
    })

    if (invalidFields.length) {
        logError(
            `Unrecognised fields:${BREAK}${invalidFields.join(', ')}`,
            name,
        )
    }
}

export const isValidBoolean = (value: unknown, location: string) => {
    if (value === undefined) {
        return false
    } else if (typeof value !== 'boolean') {
        logError(
            `Invalid field value:${BREAK}${value}${BREAK}Must be a value of boolean type.${BREAK}Note: strings containing "true" or "false" are not valid. Must be true or false as a boolean data type.`,
            location,
        )
        return false
    }
    return true
}

export const isValidString = (value: unknown, location: string) => {
    if (value === undefined) {
        return false
    } else if (typeof value !== 'string') {
        logError(
            `Invalid field value:${BREAK}${value}${BREAK}Must be a string.`,
            location,
        )
        return false
    }
    return true
}

export const isValidNumber = (value: unknown, location: string) => {
    if (value === undefined) {
        return false
    } else if (typeof value !== 'number') {
        logError(
            `Invalid field value:${BREAK}${value}${BREAK}Must be a numeric value.${BREAK}Note: strings containing numbers are not valid. For example, "88" is not valid. Must be 88 as a number data type.`,
            location,
        )
        return false
    }
    return true
}

export const isValidArray = (value: unknown, location: string) => {
    if (value === undefined) {
        return false
    } else if (!Array.isArray(value)) {
        logError(
            `Invalid field value:${BREAK}${value}.${BREAK}Must be an array.`,
            location,
        )
        return false
    }
    return true
}

export const isValidPrimitive = (value: unknown, location: string) => {
    if (value === undefined) {
        return false
    } else if (
        typeof value !== 'number' &&
        typeof value !== 'string' &&
        typeof value !== 'boolean'
    ) {
        logError(
            `Invalid field value:${BREAK}${value}${BREAK}Must be a string, boolean or numeric value.`,
            location,
        )
        return false
    }
    return true
}

export const isValidObject = (value: unknown, location: string) => {
    if (value === undefined) {
        return false
    } else if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
    ) {
        return true
    }
    logError(
        `Invalid field value:${BREAK}${value}${BREAK}Must be an object.`,
        location,
    )
    return false
}
