// Root fields
export const ACCOUNT_RADIUS_MAX = 'AccountsMaxRadiusKm'
export const ACCOUNT_RADIUS_MIN = 'AccountsMinRadiusKm'
export const AUDIT_TYPES = 'AuditTypes'
export const AUTO_DOWNLOAD_RESOURCES = 'AutoDownloadVisitResources'
export const HOME_PAGE = 'HomePage'
export const INVOICE_SETTINGS = 'InvoiceDocumentSettings'
export const SUPPORT_ENABLED = 'IsSupportEnabled'
export const OBJECTS_TO_SYNC = 'ObjectsToSync'
export const ORDER_COMPLETE = 'OrderComplete'
export const PRINTER_PAPER_HEIGHT = 'PrinterPaperHeightMm'
export const PRINTER_PAPER_WIDTH = 'PrinterPaperWidthMm'
export const RECEIPT_SETTINGS = 'ReceiptDocumentSettings'
export const SIDE_MENU = 'SideMenu'

// Child fields
export const ACTION = 'Action'
export const ACTIONS = 'Actions'
export const API_NAME = 'APIName'
export const APPLICABLE_RECORD_TYPES = 'ApplicableRecordTypes'
export const BUTTON = 'Button'
export const CAN_ADD_PRODUCTS = 'CanAddProducts'
export const CAN_CREATE = 'CanCreate'
export const CAN_DELETE = 'CanDelete'
export const CAN_EDIT = 'CanEdit'
export const CAN_CREATE_ORDERS = 'CanCreateOrders'
export const CAN_TAKE_PHOTOS = 'CanTakePhotos'
export const CHILD_OBJECT_API = 'ChildObjectApiName'
export const CHILD_OBJECT_LOOKUP = 'ChildObjectLookupApiName'
export const DETAILS = 'Details'
export const DIRECTION = 'Direction'
export const FIELDS = 'Fields'
export const FILTER_GROUPS = 'FilterGroups'
export const FILTERS = 'Filters'
export const FROM_NAME = 'FromName'
export const FOOTER = 'Footer'
export const HEADER = 'Header'
export const IN_SIDE_MENU = 'InSideMenu'
export const KEY = 'Key'
export const LAYOUT = 'Layout'
export const LAYOUT_FORM = 'LayoutForm'
export const LAYOUT_LIST_VIEW = 'LayoutListView'
export const LOGO = 'Logo'
export const NAME = 'Name'
export const OPERATOR = 'Operator'
export const OUTLET_ASSET_TO_AUDIT_ASSET = 'OutletAssetToAuditAssetFieldMapping'
export const QUICK_ADD = 'QuickAdd'
export const RECORD_SORT = 'RecordSort'
export const RECORD_LIMIT = 'RecordLimit'
export const REQUIRED = 'Required'
export const SORT_FIELD_API_NAME = 'SortFieldApiName'
export const TABS = 'Tabs'
export const TARGET = 'Target'
export const TITLE = 'Title'
export const TO_NAME = 'ToName'
export const TYPE = 'Type'
export const VALUE = 'Value'
export const VALUES = 'Values'

export const ROOT_FIELDS = [
    ACCOUNT_RADIUS_MAX,
    ACCOUNT_RADIUS_MIN,
    AUDIT_TYPES,
    AUTO_DOWNLOAD_RESOURCES,
    HOME_PAGE,
    INVOICE_SETTINGS,
    SUPPORT_ENABLED,
    OBJECTS_TO_SYNC,
    ORDER_COMPLETE,
    PRINTER_PAPER_HEIGHT,
    PRINTER_PAPER_WIDTH,
    RECEIPT_SETTINGS,
    SIDE_MENU,
]

export const AUDIT_TYPES_FIELDS = [
    BUTTON,
    CAN_ADD_PRODUCTS,
    CAN_CREATE_ORDERS,
    CAN_TAKE_PHOTOS,
    FILTER_GROUPS,
    LAYOUT_FORM,
    OUTLET_ASSET_TO_AUDIT_ASSET,
    QUICK_ADD,
    TABS,
    TITLE,
    TYPE,
]

export const OBJECTS_TO_SYNC_FIELDS = [
    ACTIONS,
    API_NAME,
    CAN_CREATE,
    CAN_DELETE,
    CAN_EDIT,
    FILTER_GROUPS,
    IN_SIDE_MENU,
    LAYOUT_FORM,
    LAYOUT_LIST_VIEW,
    RECORD_LIMIT,
    RECORD_SORT,
    TABS,
]

export const SCREEN_LAYOUT_FIELDS = [TYPE, NAME, ACTION]

export const ACTION_FIELDS = [TYPE, TITLE, TARGET]

export const DOCUMENT_SETTINGS_FIELDS = [HEADER, FOOTER, LOGO]

export const FILTER_GROUP_FIELDS = [FILTERS]

export const FILTER_FIELDS = [KEY, OPERATOR, VALUE, VALUES]

export const RECORD_SORT_FIELDS = [SORT_FIELD_API_NAME, DIRECTION]

export const TABS_FIELDS = [
    TYPE,
    TITLE,
    RECORD_LIMIT,
    RECORD_SORT,
    LAYOUT,
    FILTER_GROUPS,
    CHILD_OBJECT_LOOKUP,
    CHILD_OBJECT_API,
]

export const ASSET_FIELD_MAPPING_FIELDS = [TO_NAME, FROM_NAME]

export const LAYOUT_FIELDS = [
    TITLE,
    FIELDS,
    NAME,
    DETAILS,
    APPLICABLE_RECORD_TYPES,
]

export const LAYOUT_DETAILS_FIELDS = [REQUIRED]
