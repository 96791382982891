export const LOG_TYPES = {
    WARNING: 'WARNING',
    ERROR: 'ERROR',
    CONGRATS: 'CONGRATULATIONS',
}

export const UPDATE_ERROR_LOGS = 'update-error-logs-event'

export const BREAK = '\n\n' // Line break for error logs

export const PALETTE = {
    primaryLightest: '#E4F8F6',
    primaryLight: '#64F0E6',
    primary: '#46CCC2',
    primaryDark: '#2CBCB2',

    secondaryNavyLight: '#93C3EA',
    secondaryNavy: '#43749E',
    secondaryNavyDark: '#25547E',

    secondaryCobaltLight: '#4FC8FF',
    secondaryCobalt: '#1FACEC',
    secondaryCobaltDark: '#2888D6',

    tertiaryGreyLight: '#F0F0F0',
    tertiaryGrey: '#E0E0E0',
    tertiaryGreyDark: '#D5D5D5',
    tertiaryGreyDarkest: '#9F9F9F',

    tertiaryBlueLightest: '#F8F8FB',
    tertiaryBlueLighter: '#F7F6FB',
    tertiaryBlueLight: '#E8EBF8',
    tertiaryBlue: '#CACFE2',
    tertiaryBlueDark: '#B1B7CD',
    tertiaryBlueDarker: '#8892B4',
    tertiaryBlueDarkest: '#707893',

    labelYellow: '#FFFAEA',
    labelOrange: '#FEECDB',
    labelRed: '#FFE0E0',
    labelBlueDark: '#DFEDF9',
    labelBlue: '#E5F7FF',
    labelGreenLight: '#E8FDFB',
    labelGreen: '#EAF6EB',
    labelGrey: '#F4F4F8',

    promotionLight: '#E3E0F2',
    promotion: '#A395DE',
    promotionDark: '#2200B7',

    error: '#FF5A5A',
    errorDark: '#FF3131',
    warning: '#FFDA73',
    neutral: '#FF985A',
    neutralDark: '#F8810E',
    success: '#32AE40',

    black: '#000000',
    white: '#FFFFFF',
}
