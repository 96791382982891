import { BREAK } from '../../../config'
import {
    APPLICABLE_RECORD_TYPES,
    DETAILS,
    FIELDS,
    LAYOUT,
    LAYOUT_DETAILS_FIELDS,
    LAYOUT_FIELDS,
    LAYOUT_FORM,
    LAYOUT_LIST_VIEW,
    NAME,
    REQUIRED,
    TITLE,
} from '../../../fields'
import type {
    ConfigLayout,
    ConfigLayoutDetails,
} from '../../../types/configuration-types'
import {
    checkRecognisedFields,
    isValidArray,
    isValidBoolean,
    isValidObject,
    isValidString,
} from '../../helpers/fields'
import { logError, logFieldError, logWarning } from '../../helpers/logging'

export const checkLayout = (
    layout: ConfigLayout[],
    id: string,
    allowListOnly = false,
) => {
    if (!layout.length) {
        logWarning(
            `The ${LAYOUT} field is an empty array.${BREAK}This may result in unexpected behaviour such as blank pages or lists appearing in the app.`,
            id,
        )
    }

    layout.forEach((item, index) => {
        checkRecognisedFields(item, LAYOUT_FIELDS, id)

        const { Name, Details, Title, Fields } = item

        const isListView = !!Name || !!Details
        const isForm = !!Title || !!Fields

        const layoutId = `${id} (index ${index})`

        let hasError = false

        if (isListView && isForm) {
            logError(
                `Invalid ${LAYOUT} field.${BREAK}Layout items can be EITHER a ${LAYOUT_LIST_VIEW} item (with ${NAME} and ${DETAILS} fields) OR a ${LAYOUT_FORM} item (with ${TITLE} and ${FIELDS}).`,
                layoutId,
            )
            hasError = true
        }

        if (allowListOnly && (!isListView || isForm)) {
            logError(
                `Invalid ${LAYOUT} field.${BREAK}Layout items in this array can only be ${LAYOUT_LIST_VIEW} items.`,
                layoutId,
            )
            hasError = true
        }

        if (!hasError) {
            isListView
                ? checkLayoutListViewItem(item, layoutId)
                : checkLayoutFormItem(item, layoutId)
        }
    })
}

const checkLayoutListViewItem = (item: ConfigLayout, id: string) => {
    const { Name, Details, ApplicableRecordTypes } = item

    const nameId = `${id} -> ${NAME}`
    const detailsId = `${id} -> ${DETAILS}`
    const recordTypesId = `${id} -> ${APPLICABLE_RECORD_TYPES}`

    if (!isValidString(Name, nameId)) {
        logFieldError(NAME, nameId)
    }

    if (isValidObject(Details, detailsId)) {
        checkRecognisedFields(
            Details as ConfigLayoutDetails,
            LAYOUT_DETAILS_FIELDS,
            detailsId,
        )

        const { Required } = Details as ConfigLayoutDetails

        isValidBoolean(Required, `${detailsId} -> ${REQUIRED}`)
    }

    if (isValidArray(ApplicableRecordTypes, recordTypesId)) {
        checkApplicableRecordTypes(
            ApplicableRecordTypes as string[],
            recordTypesId,
        )
    }
}

const checkLayoutFormItem = (item: ConfigLayout, id: string) => {
    const { Title, Fields, ApplicableRecordTypes } = item

    const titleId = `${id} -> ${TITLE}`
    const fieldsId = `${id} -> ${FIELDS}`
    const recordTypesId = `${id} -> ${APPLICABLE_RECORD_TYPES}`

    isValidString(Title, titleId)

    if (isValidArray(Fields, fieldsId)) {
        Fields?.forEach((field, index) => {
            checkLayoutListViewItem(field, `${fieldsId} (index ${index})`)
        })
    }

    if (isValidArray(ApplicableRecordTypes, recordTypesId)) {
        checkApplicableRecordTypes(
            ApplicableRecordTypes as string[],
            recordTypesId,
        )
    }
}

const checkApplicableRecordTypes = (recordTypes: string[], id: string) => {
    recordTypes.forEach((record) => {
        isValidString(record, id)
    })
}
