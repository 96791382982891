import { BREAK } from '../../../config'
import { ACTION_FIELDS, TARGET, TITLE, TYPE } from '../../../fields'
import type { Action } from '../../../types/configuration-types'
import { checkRecognisedFields, isValidString } from '../../helpers/fields'
import { logError, logFieldError } from '../../helpers/logging'

// Action Type values
const APEX = 'APEX'
const DEEPLINK = 'DEEPLINK'
const ENGINE = 'ENGINE'
const WEB = 'WEB'

export const checkActionItem = (action: Action, id: string) => {
    checkRecognisedFields(action, ACTION_FIELDS, id)

    const { Type, Title, Target } = action

    if (!isValidString(Target, `${id} -> ${TARGET}`)) {
        logFieldError(
            TARGET,
            id,
            'Target should be a string defining the url or path to link to.',
        )
    }

    if (!isValidString(Title, `${id} -> ${TITLE}`)) {
        logFieldError(
            TITLE,
            id,
            `While technically optional, it’s advisable to set a value here or the app will fall back to the value of "${TARGET}" instead.`,
        )
    }

    const hasTypeField = isValidString(Type, `${id} -> ${TYPE}`)

    if (!hasTypeField) {
        logFieldError(
            TYPE,
            id,
            `This is the type of action to trigger - must be one of "${APEX}", "${DEEPLINK}", "${ENGINE}", ${WEB}".`,
        )
    } else {
        switch (Type) {
            case APEX:
            case DEEPLINK:
            case ENGINE:
            case WEB:
                break
            default:
                logError(
                    `Invalid field "${TYPE}".${BREAK}You entered "${Type}" - must be one of "${APEX}", "${DEEPLINK}", "${ENGINE}", "${WEB}".`,
                    `${id} -> ${TYPE}`,
                )
        }
    }
}
