import { ORDER_COMPLETE, SCREEN_LAYOUT_FIELDS } from '../../fields'
import type { ScreenLayoutItem } from '../../types/configuration-types'
import { checkRecognisedFields, isValidArray } from '../helpers/fields'

import {
    checkActionNamesAreUnique,
    checkScreenLayoutItem,
} from './shared/screenLayout'

export const validateOrderComplete = (items: ScreenLayoutItem[]) => {
    if (isValidArray(items, ORDER_COMPLETE)) {
        items.forEach((item, index) => {
            const id = `${ORDER_COMPLETE} (index ${index})`

            checkRecognisedFields(item, SCREEN_LAYOUT_FIELDS, id)
            checkScreenLayoutItem(item, id)
        })

        checkActionNamesAreUnique(items, ORDER_COMPLETE)
    }
}
